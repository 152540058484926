import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogTemplate.tsx";
import SEO from "Components/core/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Playing With Polygons - The Iceberg Story" description="I explore a side project that was 5 years in the making" mdxType="SEO" />
    <h2>{`Intro`}</h2>
    <p>{`This is the iceberg story of the ideas and learnings that lead to my Covid-19 Lockdown Project, `}<strong parentName="p">{`Playing With Polygons`}</strong>{`. Which is a React/P5.js app that lets you create gif's like the one below.`}</p>
    <p><img parentName="p" {...{
        "src": "/de8fbc4a89c4592375c1c4b8922c29c2/logo-gif.gif",
        "alt": "Playing with Polygons animated logo"
      }}></img></p>
    <p>{`(See and share more gifs on twitter `}<a parentName="p" {...{
        "href": "https://twitter.com/PlayingPolygons"
      }}>{`@PlayingPolygons twitter page`}</a>{`)`}</p>
    <p>{`So what is `}<strong parentName="p">{`"The Iceberg Story"`}</strong>{`? Well its the idea that what mostly gets shared is just the final hurdle, or the tip of the iceberg. But I think there is also value in sharing the whole story. So this post isn't about what I did during lockdown. Its about how a bug I created in 2014, lead to this project 6 years later.`}</p>
    <h2>{`The Beginning - Cloning`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAAEAQL/2gAMAwEAAhADEAAAAcEUYdoNJr//xAAZEAEAAgMAAAAAAAAAAAAAAAABAAIDEBH/2gAIAQEAAQUChOG8dRp//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAECEf/aAAgBAwEBPwEUaj//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAIAQIBAT8BXT//xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhQVGB/9oACAEBAAY/Ar2N4LZwRLR//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFhQVH/2gAIAQEAAT8hN1pg9+RKLowUqpMRUroiFjylrsLAV9Z//9oADAMBAAIAAwAAABA4H//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QOwg4X//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxB0GV3/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhQTFxkf/aAAgBAQABPxBEutNvr34HK5xsZAfQc4xFenH8cUZNloNlzQxxWoNMxAzKhVz/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Super hexagon screenshot",
          "title": "Super hexagon screenshot",
          "src": "/static/752a2835a23b5f2c149c703a096d3385/6a068/super-hexagon.jpg",
          "srcSet": ["/static/752a2835a23b5f2c149c703a096d3385/09b79/super-hexagon.jpg 240w", "/static/752a2835a23b5f2c149c703a096d3385/7cc5e/super-hexagon.jpg 480w", "/static/752a2835a23b5f2c149c703a096d3385/6a068/super-hexagon.jpg 960w", "/static/752a2835a23b5f2c149c703a096d3385/644c5/super-hexagon.jpg 1440w", "/static/752a2835a23b5f2c149c703a096d3385/0f98f/super-hexagon.jpg 1920w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`In 2013 I had watched Nerd Cubed play Super Hexagon. And soon after I bought the game myself, which would inspire me to clone it in university for my processing 2 module in 2014.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "88.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAEDAgUG/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB2sYRSuVA50bSFr//xAAaEAACAgMAAAAAAAAAAAAAAAAAAxMUAREg/9oACAEBAAEFAolbiUQrLSi0rBaVx//EABYRAAMAAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPwEj/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAIQESL/2gAIAQIBAT8B0U0//8QAHBAAAQQDAQAAAAAAAAAAAAAAAAECMZEgMqEh/9oACAEBAAY/AtG0esSjRtErRPCeYf/EABwQAQADAAIDAAAAAAAAAAAAAAEAETEhURBhkf/aAAgBAQABPyGoHM5FQtO4ZxDjb2gBV1fadr+prNK8f//aAAwDAQACAAMAAAAQFxC8/8QAGBEBAAMBAAAAAAAAAAAAAAAAAAERIUH/2gAIAQMBAT8QxZ1D/8QAGBEAAgMAAAAAAAAAAAAAAAAAABARQXH/2gAIAQIBAT8QgalL/8QAGxABAQEBAAMBAAAAAAAAAAAAAREAIUFRcYH/2gAIAQEAAT8QYFUfhuPshSpr+t+MmIkUHtgWaldKd69KgqqcF8ZVEqWx1fe//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Super Hexagon Clone",
          "title": "Super Hexagon Clone",
          "src": "/static/5b5f471b4c259afe1e9b7bc45c124da5/80e3c/college-project.jpg",
          "srcSet": ["/static/5b5f471b4c259afe1e9b7bc45c124da5/09b79/college-project.jpg 240w", "/static/5b5f471b4c259afe1e9b7bc45c124da5/7cc5e/college-project.jpg 480w", "/static/5b5f471b4c259afe1e9b7bc45c124da5/80e3c/college-project.jpg 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`While the game got me a good grade, it was rather lackluster. Though its role in this story is actually the bug it helped to create during development. Which was a pretty polygonal spiral - similar to the below gif - that stuck in my head for years to come.`}</p>
    <p><img parentName="p" {...{
        "src": "/8b660ccac8f577c9e4f7164bac76f0ef/polygon-closing.gif",
        "alt": "Gif of a spiral being created"
      }}></img></p>
    <h2>{`The Time Skip - 4 years later`}</h2>
    <p>{`Its now 2018 and I'm in my second year and second job as a web developer. And 4 things converge to push me to explore this bug I kept remembering.`}</p>
    <h3>{`1 - The Motivation`}</h3>
    <p>{`I'm working as a frontend developer, and es6 is starting to gain a lot of traction. Array functions, Class syntax, const and let, all things I want to learn! But my day job was in legacy jQuery, and I had no desire for my skills to stagnate.`}</p>
    <h3>{`2 - The Tool`}</h3>
    <p>{`I had recently discovered Codepen, and all the wonderful creations people post on it. Back in 2018 I didn't know how to do things like create react app, so Codepen was the answer to an IDE that allowed auto reloading.`}</p>
    <h3>{`3 - The Inspirer`}</h3>
    <p>{`At the same time I was also watching The Coding Train - a fantastic YouTube channel - who's host just oozes enthusiasm while creating visually fascinating p5.js/processing projects. Watching him I couldn't help but be inspired to create something in p5.js.`}</p>
    <h3>{`4 - The Commute`}</h3>
    <p>{`On top of all that, I had an hour long train commute every night. Which starts The Coding - on the - Train section of this story, where I slowly convert my old 2014 java processing 2 code into JavaScript p5.js es6 classes one train ride at a time.`}</p>
    <h3>{`The Result`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "425px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAMEBQIG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAb8OZqxgLyofRhwD/8QAHRAAAQQCAwAAAAAAAAAAAAAAAwABAhEEMxMUIf/aAAgBAQABBQKJIPLLvgQdxRvIfWI6Bvrxf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EAB4QAAEDBAMAAAAAAAAAAAAAAAEAAhAREiFxQVGR/9oACAEBAAY/AhRwqs9iGbRtIOVx6mbn/8QAHRABAAEEAwEAAAAAAAAAAAAAAQARITFREEGhYf/aAAgBAQABPyEYUneZcejh1GnWGKCXMWYl5caVd1PrP//aAAwDAQACAAMAAAAQy8g8/8QAFREBAQAAAAAAAAAAAAAAAAAAICH/2gAIAQMBAT8Qg//EABURAQEAAAAAAAAAAAAAAAAAACBB/9oACAECAQE/EKP/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhQTFRYfD/2gAIAQEAAT8QrewKv4AlVxTda3Np5GcZNkcqZGgBcuh0/KmZO4FnFIL0HEM7Fys//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot of playing with polygons on codepen",
          "title": "Screenshot of playing with polygons on codepen",
          "src": "/static/3291f611a4f5da9af08e28d342c80839/b9721/version-one-set.jpg",
          "srcSet": ["/static/3291f611a4f5da9af08e28d342c80839/09b79/version-one-set.jpg 240w", "/static/3291f611a4f5da9af08e28d342c80839/b9721/version-one-set.jpg 425w"],
          "sizes": "(max-width: 425px) 100vw, 425px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This all came together into a collection of 6 Codepen pens. Each a fork of the last, telling the story - in code - of copying, and then adding to the polygons created in 2014.`}</p>
    <h2>{`The First Attempt - Playing with Polygons Prototype`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.58333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB0ElEQVQoz5VSS2/TQBj0z0bihMSFA0LiQuFQqQiJx6lApNIHAVzBISGkaUgqEDFK4vXbjmMnTuI4DDtrObTAAVYa7efdmfnmW1lzXRdBGIJrOp0iTVNsNhus1+srIC9JE8WLogij0QiO42A+nyPLMrUvFgtovu8hmkwUcTabYSLrIAgUgcZFUSiEUYhcGleNaZYkiTLJ81yBjbVQpuMFF9OxOy8qQrnniONY1qUh+Uw4Ho9hmiaWy+XWVItkZ3ZUhrMUlmUpMUUcJcvKkYLAl7xY8arxaLBara4m9H0fTMkLJnRdT+0U8Wy1ZMoCSZwinWT4UZSTUMPnoTF5TEloQgh4nqdg2zbYoIIXuDCMBrrdY+jfH0P3HmLoXGz5f4PGEWlagW9iChPWyMF5vw790w6Ozu5g/+MNPD2/jjedXQyNIYTUkXtZS6iENP2F8pvnJ++f4PD0PppfXqDb+4Cjxg4Oanv41uvDcR38qbXKhJdRdpK1a0J/e4za67totQ/x1WhDbz7C84N76F005W/j/ZuhJWhqwrF9tM7q2O/cxMv2bbzq3MK71gPoPTnywIAl3/s/EgplPBQDnBp7ePb5GuqNXbT7NQzMLmzhbDm/638Cq50s+HL0K38AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot from playing with polygons prototype",
          "title": "Screenshot from playing with polygons prototype",
          "src": "/static/a78ea28de838e92ba5fc8f6f8b17812b/d9199/version-two-example.png",
          "srcSet": ["/static/a78ea28de838e92ba5fc8f6f8b17812b/8ff5a/version-two-example.png 240w", "/static/a78ea28de838e92ba5fc8f6f8b17812b/e85cb/version-two-example.png 480w", "/static/a78ea28de838e92ba5fc8f6f8b17812b/d9199/version-two-example.png 960w", "/static/a78ea28de838e92ba5fc8f6f8b17812b/e72de/version-two-example.png 1198w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`During the Codepen experiments I shared some screenshots and videos with a friend. His positive responses inspired me to find a way for him to play with settings for the polygons I had created.`}</p>
    <p>{`At the same time I was also learning react. So it felt like the perfect excuse to refine my react skills and create something interactive.`}</p>
    <p>{`However, as things tend to go when you don't have the experience, it became a total mess. Only 1 polygon could be controlled, and the UI became so tightly coupled to the canvas that it became impossible to move forward.`}</p>
    <p>{`And while I do consider this little project a bit of a failure, I still finished it. No matter how bad the project turns out, I will always encourage people to just finish it. Even if you have to change the definition of finished. It will help refine the skill of wrapping up a project, and hopefully help to make it a habit.`}</p>
    <h2>{`An Impactful Failure - Yahtzee Redux`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.08333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHrxVdZahQP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIBAxAUIv/aAAgBAQABBQLrOuotKyRQsH//xAAYEQACAwAAAAAAAAAAAAAAAAAAAhEhUf/aAAgBAwEBPwGXws//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPwGH/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEhMTJB/9oACAEBAAY/Ato0jRbHcM6f/8QAHBABAAICAwEAAAAAAAAAAAAAAQARIZFBUYHR/9oACAEBAAE/IVt8YLxV5BerqbS5nVLTDC6GP//aAAwDAQACAAMAAAAQVO//xAAZEQACAwEAAAAAAAAAAAAAAAAAASExofD/2gAIAQMBAT8Q4Mna1n//xAAWEQEBAQAAAAAAAAAAAAAAAAAAIXH/2gAIAQIBAT8Q0j//xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMUHRYf/aAAgBAQABPxCpIVlfGUgbgrDccPcxvvxKeZUtQINDvjWOo63aeZ//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot of Yahtzee Game",
          "title": "Screenshot of Yahtzee Game",
          "src": "/static/0bcd58cc74d1e68b6443cdc9f3fa84d5/b2cd5/yahtzee-redux.jpg",
          "srcSet": ["/static/0bcd58cc74d1e68b6443cdc9f3fa84d5/09b79/yahtzee-redux.jpg 240w", "/static/0bcd58cc74d1e68b6443cdc9f3fa84d5/7cc5e/yahtzee-redux.jpg 480w", "/static/0bcd58cc74d1e68b6443cdc9f3fa84d5/b2cd5/yahtzee-redux.jpg 828w"],
          "sizes": "(max-width: 828px) 100vw, 828px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`It's now July 2019, and I'm looking for another quick React side project to improve my understanding of hooks. So I turn to recreating a Yahtzee game I made in university.`}</p>
    <p>{`However, I let features creep in that weren't in the original. And a simple project that seemed manageable without tests and types ballooned in complexity. Leading to a codebase that became increasingly difficult to add to.`}</p>
    <p>{`My lack of planning ahead of time meant that features didn't mesh well together. And I was constantly refactoring each time I added a new idea.`}</p>
    <p>{`My lack of tests meant that any logic changes I made resulted in time consuming manual testing. Testing which could have easily been written if I planned for it.`}</p>
    <p>{`And my lack of types meant that I had to keep data structures in my active memory constantly, slowing me down as I worked in different areas of the project.`}</p>
    <p>{`In the end I just had to wrap it up and get it to a playable state.`}</p>
    <h2>{`Lessons Learned - Playing with Polygons`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "637px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqUlEQVQ4y41Ua2+CQBDk//8u+6EWlRJrfHzQJvJSQAERBHW7s82RQ7D1kg33nJmdvcMoioIulwuVZSmBflVVpNr9fqfr9Uq3243qupaxatinziCAZWCT3rI0JfNjSOOJRdPpVOJtMCBzNJL++5DXxmOyPm3Ksqx1FqSGUqOYQRBFIW02G1qv1+S4Lh0OB9rtdgyQ0jfPf81m5LoelaxIP4tMWoB6Ok3KzIpUELoVuiVPAR835KcTq43oeDyKSvRTtgSp6fv+BVSGAygMQ4n9fk9xHEucz+cW+UuASBGKAOD7Pnv26yWAAdiX0UuACgRFSZJE5k5sxUsK9UVUWwEgdXinCAqtuh3Ax3uoN5DBvyAIRCGU9oF1FOpV61Oqx19NLnae5+Q6jqgQr/jrOC6HQ9vtVhRCHcZ4GVAB4KqqO2QII2F/THPEhx2Ko7ipbMCV9ThCJvE9X3wEIQjQx0tBH3sxj5sAQgNXABMqdShQPwM11vvdqOXbKQoY5vM5rVYrWi6XTWBs2zZNLKs1r9YWi4W8e0XaXBuk4XmeAD8GLjU87FvDGaSufnM/DCrS2zqWH5EAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Playing with polygons start screen",
          "title": "Playing with polygons start screen",
          "src": "/static/b7521bf5f671ac6d25da08e45bb67091/13a9a/final-project.png",
          "srcSet": ["/static/b7521bf5f671ac6d25da08e45bb67091/8ff5a/final-project.png 240w", "/static/b7521bf5f671ac6d25da08e45bb67091/e85cb/final-project.png 480w", "/static/b7521bf5f671ac6d25da08e45bb67091/13a9a/final-project.png 637w"],
          "sizes": "(max-width: 637px) 100vw, 637px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This all leads me to 2020, where between April 19th and July 19th I started and finished `}<strong parentName="p">{`Playing with Polygons`}</strong></p>
    <p>{`Which lead to - what I consider - a successful little project. That anyone can use at its `}<a parentName="p" {...{
        "href": "https://liammyles.github.io/playing-with-polygons/"
      }}>{`playing with polygons gh-page`}</a>{`.`}</p>
    <h2>{`In Closing`}</h2>
    <p>{`I wanted to write something that showed the false starts and learnings that go into an idea. No website or app appears out of some magical burst of intellect. They all have a story below the surface that goes much deeper then you would think.`}</p>
    <p>{`If you have any iceberg stories please do share, I would love to hear them! You can reach out to me in the comments or on `}<a parentName="p" {...{
        "href": "https://twitter.com/LiamMyles92"
      }}>{`my twitter @LiamMyles92`}</a></p>
    <p>{`Also if you found any of this interesting please do let me know. The next post I'm working on is a break down of how I built Playing with Polygons. So any encouragement would be a great boon.`}</p>
    <p>{`Thanks for reading :)`}</p>
    <h2>{`References`}</h2>
    <h3>{`Coding Tools/Libraries Mentioned`}</h3>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/"
      }}>{`Codepen`}</a>{`
`}<a parentName="p" {...{
        "href": "https://processing.org/"
      }}>{`Processing`}</a>{`
`}<a parentName="p" {...{
        "href": "https://p5js.org/"
      }}>{`P5.js`}</a>{`
`}<a parentName="p" {...{
        "href": "https://create-react-app.dev/"
      }}>{`Create React App`}</a></p>
    <h3>{`Social platforms mentioned`}</h3>
    <p><a parentName="p" {...{
        "href": "https://thecodingtrain.com/"
      }}>{`The Coding Train`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.nerdcubed.co.uk/videos/nerdcubed-permadeath-super-hexagon/"
      }}>{`Nerd Cubed playing super hexagon`}</a>{`
`}<a parentName="p" {...{
        "href": "https://superhexagon.com/"
      }}>{`Super Hexagon Website`}</a>{`
`}<a parentName="p" {...{
        "href": "https://twitter.com/PlayingPolygons/status/1284533390742450177"
      }}>{`Super Hexagon Twitter`}</a></p>
    <h3>{`My Mentioned Code`}</h3>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/collection/XdvEbe"
      }}>{`Codepen Collection`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/LiamMyles/Playing-With-Polygons-Prototype"
      }}>{`Playing With Polygons Prototype`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/LiamMyles/yahtzee-redux"
      }}>{`Yahtzee Redux`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/LiamMyles/playing-with-polygons"
      }}>{`Playing With Polygons`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      